import { useEffect, useState } from 'react';

const useDomLoaded = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setLoaded(true);
    };

    // Check if DOM has been loaded completely
    if (global.document.readyState === 'complete') {
      onPageLoad();
    } else {
      /* If DOM is not loaded then attach a event listener */
      window.addEventListener('load', onPageLoad);
    }

    /* Clear event listener if any */
    return () => window.removeEventListener('load', onPageLoad);
  });

  return loaded;
};

export default useDomLoaded;
