const manageBodyOverflow = (displayState) => {
  const { body } = global.document;

  if (global.document.documentElement) {
    global.document.documentElement.style.setProperty('overflow', displayState ? 'hidden' : 'visible');
  }

  if (body) {
    if (displayState) body.style.setProperty('overflow', 'hidden');
    else body.style.setProperty('overflow', 'auto');
  }
};

export default manageBodyOverflow;
